import styles from "./index.module.css";

export default ($attrs) => {
  const $min = $attrs.map("min", (current) => (current == null ? 0 : current));
  const $max = $attrs.map("max", (current) =>
    current == null ? 100 : current
  );
  const $step = $attrs.map("step", (current) =>
    current == null ? 1 : current
  );
  const $value = $attrs.map("value");
  const onchange = $attrs.get("onchange");

  return (
    <input
      type="range"
      class={[styles.input, $attrs.get("class")]}
      min={$min}
      max={$max}
      step={$step}
      value={$value}
      oninput={onchange}
    />
  );
};
