import { makeState } from "@woofjs/client";
import chroma from "chroma-js";
import { delayer } from "utils/delayer";

const localStorageKey = "chromatose-chosen-color";

export default (self) => {
  const { $query } = self.getService("@router");

  const $raw = makeState(chroma.random().gl());
  const $chroma = $raw.map((current) => chroma.gl(...current));
  const $hex = $chroma.map((current) => current.hex());

  self.beforeConnect(() => {
    // Load color from URL or storage if present when app loads.
    let selected = $query.get("color");

    if (selected) {
      if (chroma.valid(selected)) {
        $raw.set(chroma(selected).gl());
      }
    } else {
      selected = localStorage.getItem(localStorageKey);

      if (selected) {
        selected = JSON.parse(selected);

        $raw.set(selected);
        $query.set((current) => {
          current.color = $hex.get().slice(1);
        });
      }
    }
  });

  // Update URL when color changes.
  const debounceChroma = delayer(50);
  self.watchState($chroma, (color) => {
    debounceChroma(() => {
      $query.set((current) => {
        current.color = color.hex().slice(1);
      });
    });
  });

  // Update localStorage when color changes.
  const debounceRaw = delayer(100);
  self.watchState($raw, (raw) => {
    debounceRaw(() => {
      localStorage.setItem(localStorageKey, JSON.stringify(raw));
    });
  });

  return {
    $raw,
    $chroma,
    $hex,
  };
};
