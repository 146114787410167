import { makeState } from "@woofjs/client";

const localStorageKey = "chromatose-saved-palette";

export default (self) => {
  const { $query } = self.getService("@router");

  const $palette = makeState([]);

  self.beforeConnect(() => {
    // Get colors from URL or storage on first load.
    if ($query.get("palette")) {
      $palette.set(
        $query
          .get("palette")
          .split("-")
          .map((x) => "#" + x)
      );
    } else {
      const palette = JSON.parse(
        localStorage.getItem(localStorageKey) || "null"
      );

      if (palette) {
        $palette.set(palette);
      }
    }
  });

  // Update URL and storage when colors change.
  self.watchState($palette, (colors) => {
    localStorage.setItem(localStorageKey, JSON.stringify(colors));

    $query.set((query) => {
      if (colors.length === 0) {
        delete query.palette;
      } else {
        query.palette = colors.map((c) => c.slice(1)).join("-");
      }
    });
  });

  return {
    $palette,
  };
};
