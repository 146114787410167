import styles from "./index.module.css";
import Widget from "components/Widget";
import RangeInput from "components/RangeInput";

export default ($attrs, self) => {
  const { $raw, $chroma } = self.getService("color");

  return (
    <Widget title="RGBA" open={$attrs.map("open")}>
      <div
        class={styles.row}
        style={{
          "--start-color": $chroma.map((current) => {
            return current.set("rgb.r", 0).css();
          }),
          "--end-color": $chroma.map((current) => {
            return current.set("rgb.r", 255).css();
          }),
        }}
      >
        <span class={styles.controlLabel}>R</span>
        <RangeInput
          class={styles.colorSlider}
          min={0}
          max={1}
          step={0.001}
          value={$raw.map("[0]")}
          onchange={(e) => {
            e.preventDefault();
            e.stopPropagation();

            $raw.set((rgba) => {
              rgba[0] = Number(e.target.value);
            });
          }}
        />
      </div>
      <div
        class={styles.row}
        style={{
          "--start-color": $chroma.map((current) => {
            return current.set("rgb.g", 0).css();
          }),
          "--end-color": $chroma.map((current) => {
            return current.set("rgb.g", 255).css();
          }),
        }}
      >
        <span class={styles.controlLabel}>G</span>
        <RangeInput
          class={styles.colorSlider}
          min={0}
          max={1}
          step={0.001}
          value={$raw.map("[1]")}
          onchange={(e) => {
            e.preventDefault();
            e.stopPropagation();

            $raw.set((rgba) => {
              rgba[1] = Number(e.target.value);
            });
          }}
        />
      </div>
      <div
        class={styles.row}
        style={{
          "--start-color": $chroma.map((current) => {
            return current.set("rgb.b", 0).css();
          }),
          "--end-color": $chroma.map((current) => {
            return current.set("rgb.b", 255).css();
          }),
        }}
      >
        <span class={styles.controlLabel}>B</span>
        <RangeInput
          class={styles.colorSlider}
          min={0}
          max={1}
          step={0.001}
          value={$raw.map("[2]")}
          onchange={(e) => {
            e.preventDefault();
            e.stopPropagation();

            $raw.set((rgba) => {
              rgba[2] = Number(e.target.value);
            });
          }}
        />
      </div>
      <div class={styles.row}>
        <span class={styles.controlLabel}>A</span>
        <RangeInput
          min={0}
          max={1}
          step={0.001}
          value={$raw.map("[3]")}
          onchange={(e) => {
            e.preventDefault();
            e.stopPropagation();

            $raw.set((rgba) => {
              rgba[3] = Number(e.target.value);
            });
          }}
        />
      </div>
    </Widget>
  );
};
