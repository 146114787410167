/**
 * Creates a delayer function that calls a given function after
 * a given amount of milliseconds. If called with another function
 * before the previous one is called, the new function replaces the
 * old and the timer is reset.
 *
 * @example
 * const delay = delayer(300);
 *
 * const delayed = delay(() => {
 *   console.log("Not printed")
 * });
 *
 * const overwritten = delay(() => {
 *   console.log("This overwrites the previous call.")
 * });
 */
export function delayer(timeout) {
  let delayed = null;

  return function callAfterTimeout(fn) {
    if (delayed) {
      clearTimeout(delayed);
    }

    const _delayed = setTimeout(() => {
      delayed = null;
      fn();
    }, timeout);

    delayed = _delayed;

    return function cancel() {
      if (delayed === _delayed) {
        clearTimeout(delayed);
      }
    };
  };
}
