import styles from "./index.module.css";

/**
 * Collapsible container for color controls.
 */
export default ($attrs, { children }) => {
  const $open = $attrs.map("open");
  const $title = $attrs.map("title");

  return (
    <details class={styles.widget} open={$open}>
      <summary>{$title}</summary>
      <div class={styles.content}>{children}</div>
    </details>
  );
};
