import styles from "./index.module.css";
import Widget from "components/Widget";
import ToolButton from "components/ToolButton";

function fmtFloat(value, fractionDigits = 3) {
  value = value.toFixed(fractionDigits);
  if (/\.0+$/.test(value)) {
    value = value.split(".")[0];
  }
  return value;
}

export default ($attrs, self) => {
  self.debug.name = "CopyWidget";

  const { $raw, $hex, $chroma } = self.getService("color");

  const $rgb = $chroma.map((current) => {
    const rgba = current.rgba(false);

    const r = Math.round(rgba[0]);
    const g = Math.round(rgba[1]);
    const b = Math.round(rgba[2]);

    return `rgb(${r}, ${g}, ${b})`;
  });

  const $rgba = $chroma.map((current) => {
    const rgba = current.rgba(false);

    const r = Math.round(rgba[0]);
    const g = Math.round(rgba[1]);
    const b = Math.round(rgba[2]);
    const a = fmtFloat(rgba[3]);

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  });

  const $hsl = $chroma.map((current) => {
    const hsla = current.hsl();
    const h = Math.round(isNaN(hsla[0]) ? 0 : hsla[0]);
    const s = fmtFloat(hsla[1] * 100, 1) + "%";
    const l = fmtFloat(hsla[2] * 100, 1) + "%";

    return `hsla(${h}, ${s}, ${l})`;
  });

  const $hsla = $chroma.map((current) => {
    const hsla = current.hsl();
    const h = Math.round(isNaN(hsla[0]) ? 0 : hsla[0]);
    const s = fmtFloat(hsla[1] * 100, 1) + "%";
    const l = fmtFloat(hsla[2] * 100, 1) + "%";
    const a = fmtFloat(hsla[3]);

    return `hsla(${h}, ${s}, ${l}, ${a})`;
  });

  const $gl = $raw.map((current) => {
    const r = fmtFloat(current[0]);
    const g = fmtFloat(current[1]);
    const b = fmtFloat(current[2]);

    return `Color(${r}, ${g}, ${b})`;
  });

  const $gla = $raw.map((current) => {
    const r = fmtFloat(current[0]);
    const g = fmtFloat(current[1]);
    const b = fmtFloat(current[2]);
    const a = fmtFloat(current[3]);

    return `Color(${r}, ${g}, ${b}, ${a})`;
  });

  return (
    <Widget title="Copy" open={$attrs.map("open")}>
      <div class={styles.copy}>
        <ColorItem value={$hex} />

        <div class={styles.divider} />

        <ColorItem value={$rgb} />
        <ColorItem value={$rgba} />

        <div class={styles.divider} />

        <ColorItem value={$hsl} />
        <ColorItem value={$hsla} />

        <div class={styles.divider} />

        <ColorItem value={$gl} />
        <ColorItem value={$gla} />
      </div>
    </Widget>
  );
};

const ColorItem = ($attrs, self) => {
  const $value = $attrs.map("value");

  return (
    <div class={styles.row}>
      <span class={styles.copyLabel}>{$value}</span>
      <ToolButton
        title="copy color"
        onclick={() => {
          self.getService("clipboard").copy($value.get());
        }}
      >
        copy
      </ToolButton>
    </div>
  );
};
