export default {
  "app": "_app_ykmrf_29",
  "display": "_display_ykmrf_59",
  "checkerboard": "_checkerboard_ykmrf_75",
  "ui": "_ui_ykmrf_84",
  "logo": "_logo_ykmrf_88",
  "input": "_input_ykmrf_96",
  "widgets": "_widgets_ykmrf_101",
  "randomize": "_randomize_ykmrf_105",
  "die": "_die_ykmrf_115",
  "dieDot": "_dieDot_ykmrf_121"
};import "ni:sha-256;fMZIBqoKPUd8plt3QXM7yKN0C_YmV26gw7RQUD_W8ps";