import styles from "./index.module.css";

export default ($attrs, { children }) => {
  return (
    <button
      class={styles.button}
      title={$attrs.map("title")}
      onclick={$attrs.get("onclick")}
    >
      {children}
    </button>
  );
};
