import styles from "./index.module.css";
import Widget from "components/Widget";
import RangeInput from "components/RangeInput";
import { hslToRGB, rgbToHSL } from "utils/convert";

export default ($attrs, self) => {
  const { $raw, $chroma } = self.getService("color");
  const $hsla = $raw.map(rgbToHSL);

  function updateColor(h, s, l, a) {
    $raw.set(hslToRGB([h, s, l, a]));
  }

  return (
    <Widget title="HSLA" open={$attrs.map("open")}>
      <div class={styles.row}>
        <span class={styles.controlLabel}>H</span>
        <RangeInput
          class={styles.hueSlider}
          min={0}
          max={359}
          step={1}
          value={$hsla.map("[0]")}
          onchange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const value = Number(e.target.value);
            const [h, s, l, a] = $hsla.get();
            updateColor(value, s, l, a);
          }}
        />
      </div>
      <div
        class={styles.row}
        style={{
          "--start-color": $chroma.map((current) => {
            return current.set("hsl.s", 0).css();
          }),
          "--end-color": $chroma.map((current) => {
            return current.set("hsl.s", 1).css();
          }),
        }}
      >
        <span class={styles.controlLabel}>S</span>
        <RangeInput
          class={styles.colorSlider}
          min={0}
          max={1}
          step={0.001}
          value={$hsla.map("[1]")}
          onchange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const value = Number(e.target.value);
            const [h, s, l, a] = $hsla.get();
            updateColor(h, value, l, a);
          }}
        />
      </div>
      <div
        class={styles.row}
        style={{
          "--start-color": $chroma.map((current) => {
            return current.set("hsl.l", 0).css();
          }),
          "--end-color": $chroma.map((current) => {
            return current.set("hsl.l", 1).css();
          }),
        }}
      >
        <span class={styles.controlLabel}>L</span>
        <RangeInput
          class={styles.colorSlider}
          min={0}
          max={1}
          step={0.001}
          value={$hsla.map("[2]")}
          onchange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const value = Number(e.target.value);
            const [h, s, l, a] = $hsla.get();
            updateColor(h, s, value, a);
          }}
        />
      </div>
      <div class={styles.row}>
        <span class={styles.controlLabel}>A</span>
        <RangeInput
          min={0}
          max={1}
          step={0.001}
          value={$hsla.map("[3]")}
          onchange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const value = Number(e.target.value);
            const [h, s, l] = $hsla.get();
            updateColor(h, s, l, value);
          }}
        />
      </div>
    </Widget>
  );
};
