import { makeState } from "@woofjs/client";
import chroma from "chroma-js";
import styles from "./index.module.css";

export default ($attrs, self) => {
  const $color = $attrs.get("$color");

  const $inputValue = makeState("#fff");
  const $inputParseable = makeState(true);

  let isEntering = false;

  self.watchState(
    $color,
    (color) => {
      if (isEntering) {
        isEntering = false;
      } else {
        $inputValue.set(chroma.gl(...color).hex());
        $inputParseable.set(true);
      }
    },
    { immediate: true }
  );

  function onInput(e) {
    const { value } = e.target;
    const parseable = chroma.valid(value);

    $inputValue.set(value);
    $inputParseable.set(parseable);

    if (parseable) {
      isEntering = true;
      $color.set(chroma(value).gl());
    }
  }

  return (
    <input
      type="text"
      class={styles.input}
      aria-invalid={$inputParseable.map((yes) => (yes ? "false" : "true"))}
      value={$inputValue}
      placeholder="e.g. green, #f08, rgb(255, 0, 0)"
      onchange={onInput}
      autocorrect="false"
      autocomplete="false"
      spellcheck="false"
    />
  );
};
