export default (self) => {
  function copy(text) {
    if (
      "clipboard" in navigator &&
      typeof navigator.clipboard.writeText === "function"
    ) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          self.debug.log("copied:", text);
        })
        .catch(self.debug.error);
    } else if (typeof document.execCommand === "function") {
      const el = document.createElement("input");
      el.textContent = text;

      const oldContentEditable = el.contentEditable;
      const oldReadOnly = el.readOnly;
      const range = document.createRange();

      el.contentEditable = true;
      el.readOnly = false;
      range.selectNodeContents(el);

      const s = window.getSelection();
      s.removeAllRanges();
      s.addRange(range);

      el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

      el.contentEditable = oldContentEditable;
      el.readOnly = oldReadOnly;

      // console.log(range, el.textContent);

      document.execCommand("copy");
    } else {
      self.debug.error(
        `Clipboard API and execCommand are not supported in this browser. Failed to copy to clipboard.`
      );
    }
  }

  return {
    copy,
  };
};
