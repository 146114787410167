import { makeApp } from "@woofjs/client";
import { perceivedLightness } from "./utils/perceivedLightness";
import chroma from "chroma-js";
import styles from "./app.module.css";

import ClipboardService from "./services/clipboard";
import ColorService from "./services/color";
import PaletteService from "./services/palette";

import ColorTextInput from "./components/ColorTextInput";
import Logo from "./components/Logo";
import ToolButton from "./components/ToolButton";

import CopyWidget from "./widgets/CopyWidget";
import HSLAWidget from "./widgets/HSLAWidget";
import PaletteWidget from "./widgets/PaletteWidget";
import RGBAWidget from "./widgets/RGBAWidget";

const app = makeApp();

app.service("clipboard", ClipboardService);
app.service("color", ColorService);
app.service("palette", PaletteService);

app.route("*", ($attrs, self) => {
  const { $raw, $chroma, $hex } = self.getService("color");

  return (
    <div
      class={styles.app}
      style={{
        "--chosen-color": $hex,
        "--chosen-color-solid": $chroma.map((color) => {
          return chroma(color).alpha(1).hex();
        }),
        "--control-color": $raw.map((color) => {
          if (perceivedLightness(color) > 0.6) {
            return "#000";
          } else {
            return "#fff";
          }
        }),
      }}
    >
      <div class={styles.display}>
        <div class={styles.checkerboard} />
        <div class={styles.fill} style={{ backgroundColor: $hex }} />
      </div>

      <div class={styles.ui}>
        <div class={styles.logo}>
          <Logo />

          <ToolButton
            onclick={(e) => {
              e.preventDefault();
              $raw.set(chroma.random().gl());
            }}
          >
            randomize
          </ToolButton>
        </div>
        <div class={styles.input}>
          <ColorTextInput $color={$raw} />
        </div>
        <div class={styles.widgets}>
          <RGBAWidget open />
          <HSLAWidget open />
          <PaletteWidget open />
          <CopyWidget open />
        </div>
      </div>
    </div>
  );
});

app.connect("#app");
