import { unless, when, repeat } from "@woofjs/client";
import styles from "./index.module.css";
import chroma from "chroma-js";
import Widget from "components/Widget";
import ToolButton from "components/ToolButton";

export default ($attrs, self) => {
  const { $raw } = self.getService("color");
  const { $palette } = self.getService("palette");
  const $hasColors = $palette.map((colors) => colors.length > 0);

  return (
    <Widget title="Palette" open={$attrs.map("open")}>
      <div class={styles.toolbar}>
        <ToolButton
          onclick={(e) => {
            e.preventDefault();

            $palette.set((colors) => {
              colors.unshift(chroma.gl(...$raw.get()).hex());
            });
          }}
        >
          save color
        </ToolButton>
        <ToolButton
          onclick={(e) => {
            e.preventDefault();

            $palette.set([]);
          }}
        >
          clear all
        </ToolButton>
      </div>
      {when($hasColors, () => (
        <ul class={styles.list}>
          {repeat($palette, ($attrs, self) => {
            const $color = $attrs.map("value");

            return (
              <li>
                <div class={styles.display}>
                  <div
                    class={styles.swatch}
                    style={{ backgroundColor: $color }}
                  />
                  <span class={styles.text}>{$color}</span>
                </div>

                <div class={styles.itemControls}>
                  <ToolButton
                    onclick={(e) => {
                      e.preventDefault();

                      $raw.set(chroma($color.get()).gl());
                    }}
                  >
                    select
                  </ToolButton>
                  <ToolButton
                    onclick={(e) => {
                      e.preventDefault();

                      $palette.set((colors) => {
                        return colors.filter((c) => c !== $color.get());
                      });
                    }}
                  >
                    delete
                  </ToolButton>
                </div>
              </li>
            );
          })}
        </ul>
      ))}

      {unless($hasColors, () => (
        <div class={styles.empty}>no colors saved</div>
      ))}
    </Widget>
  );
};
