import styles from "./index.module.css";
import logo from "./logo.svg";

export default () => {
  return (
    <div class={styles.logo}>
      <img class={styles.icon} alt="chromatose logo" src={logo} />
      <div class={styles.text}>
        <span lang="ja" title="chromatose">
          クロマトス
        </span>
      </div>
    </div>
  );
};
